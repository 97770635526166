<template>
  <Header v-if="isMobile() == false" />
  <router-view />
  <Footer
    v-if="isMobile() == false"
    :show-image="true"
  />
</template>

<script>
import 'bootstrap';
import Footer from '../components/common/Footer.vue';
import Header from '../components/common/Header.vue';
// import Login from './common/modal/Login';
// import Signup from './common/modal/Signup';
import CommonHandler from '../components/common/CommonHandler.js';

export default {
  name: 'BecomeTraveller',
  components: { Header, Footer },
  props: ['page', 'type'],
  data() {
    return { ismobileapp: false };
  },
  watch: {
    //eslint-disable-next-line
    $route(to, from) {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    window.addEventListener('scroll', CommonHandler.handleScrollNormalPage);
  },
  unmounted() {
    window.removeEventListener('scroll', CommonHandler.handleScrollNormalPage);
  },
  methods: {
    isMobile() {
      let currentUrl = this.$route.path;
      let tmpWhiteListUrl = [
        //quick link
        '/about-us',
        '/user-terms',
        '/privacy-policy',
        '/copyright',
        '/security-policy',
        '/news-room',
        //tool
        '/prohibited-or-restricted-items',
        '/service-rate-calculations/people',
        '/service-rate-calculations/package',
        '/service-rate-calculations/product',
        '/customs-excise-duty',
        '/overseas-online-merchants',
        '/weight-and-dimensions',
        '/contact-us',
      ];
      if (tmpWhiteListUrl.includes(currentUrl)) {
        if (this.$route.query.ismobileapp != undefined) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
/*@import '../../../node_modules/animate.css/animate.css';*/
@import '../assets/css/fontawesome-5.15.2/css/all.min.css';
@import '../assets/scss/common';
</style>
<style scoped lang="scss"></style>
